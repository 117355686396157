import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import TopLinkItem from '@jsv2/components/Header/components/TopLinkItem';
import { getLinkToGo, getBackLinkToGo } from '@jsv3/utils/urlUtils';

/**
 * Render a link button "What We Do"
 *
 * @return {*}
 *
 * @constructor
 */
const WhatWeDo = ({ shouldGoBack }) => (
  <TopLinkItem
    id="what-we-do-button"
    title="What We Do"
    href={getLinkToGo() + (shouldGoBack ? `&go_back_url=${getBackLinkToGo()}` : '')}
  />
);

WhatWeDo.propTypes = {
  shouldGoBack: PropTypes.bool,
};

WhatWeDo.defaultProps = {
  shouldGoBack: false,
};

export default ErrorBoundaryDecorator()(WhatWeDo);
