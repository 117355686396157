import React from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Cart from '../../components/Header/Items/Action/Cart';
import Join from '../../components/Header/Items/Action/Join';
import Reserve from '../../components/Header/Items/Action/Reserve';

import { isAnonymous, isIncompleteSignUp } from '../../utils/UserUtils/userStatuses';

// TODO: pass from context
const { userRoles } = window;
const { offer } = window.__SERVER_DATA__;

// TODO: pass from context
const itemsInCart = window.__SERVER_DATA__.itemsCountInCart;
const showCartButton = () =>
  itemsInCart > 0 &&
  !/^\/checkout/.test(window.location.pathname) && // checkout page
  !/^#\/package\//.test(window.location.hash) && // booking and room details pages
  !/^\/password/.test(window.location.pathname); // password reset
const previewPage = () => /^\/preview/.test(window.location.pathname);
const signInPage = () => /^\/sign-in/.test(window.location.pathname);

/**
 * Returns CTA button depending on user status and cart items.
 *
 * @returns <ActionButton />
 */
const ActionButton = () => {
  if (showCartButton() && !signInPage()) {
    return <Cart key="cart" itemsInCart={itemsInCart} />;
  }

  if (isAnonymous(userRoles)) {
    if (offer && !previewPage()) {
      return <Reserve key="reserve" />;
    }

    return <Join key="join" />;
  }

  if (isIncompleteSignUp(userRoles) && offer) {
    return <Reserve key="reserve" />;
  }

  return null;
};

export default ErrorBoundaryDecorator()(ActionButton);
