import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ScreenTypeContext, { isMobile, isTablet } from '@js/context/ScreenTypeContext';
import BackButton from '@jsv2/components/Header/Items/Action/BackButton';
import { getUrlParam } from '@jsv3/utils/urlUtils';
import HeaderMenu from '../../config/Header/HeaderMenu';
import ActionButton from '../../config/Header/ActionButton';
import PhoneButton from '../../config/Header/PhoneButton';
import Logo from './Items/Logo';

const Header = React.forwardRef((props, ref) => {
  const { show, phoneNumber } = props;
  const screenTypeContext = useContext(ScreenTypeContext);
  const [isTopMenuVisible, setTopMenuVisible] = useState(false);

  if (!show) {
    return null;
  }

  const renderNavList = () => {
    if (HeaderMenu) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={`navlist__wrap ${isTopMenuVisible ? 'isOpen' : ''}`}
          data-qa-id="header_menu"
          onClick={() => setTopMenuVisible(!isTopMenuVisible)}
        >
          <nav className="navlist" data-qa-id="menu_list">
            {HeaderMenu}
          </nav>
        </div>
      );
    }

    return null;
  };

  const renderLeftActionLink = () => {
    if (getUrlParam('go_back_url')) {
      return <BackButton />;
    }

    return <Logo />;
  };

  const showTabletPhoneButton = isTablet(screenTypeContext) && phoneNumber;

  const showMobilePhoneButton = isMobile(screenTypeContext) && phoneNumber;

  return (
    <>
      <header className="main-header fixed" id="main-header" ref={ref} data-qa-id="qa_header_block">
        <div
          className={classNames('main-header__wrapper', {
            'with-right-action-button': showMobilePhoneButton,
          })}
        >
          {renderLeftActionLink()}

          {showTabletPhoneButton && <PhoneButton phoneNumber={phoneNumber} />}

          {renderNavList()}

          {!isMobile(screenTypeContext) && <ActionButton />}

          {showMobilePhoneButton && <PhoneButton phoneNumber={phoneNumber} />}
        </div>
      </header>
    </>
  );
});

const mapStateToProps = (state) => ({
  show: state.header.show,
  phoneNumber: state.header.phoneNumber,
});

Header.displayName = 'Header';

export default ErrorBoundaryDecorator()(connect(mapStateToProps)(Header));
