import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import './polyfills';
import './utils/logger-init';
import './feature-detector';
import './langs-setup';
import { getCurrentScreenType } from '@js/utils/deviceWidthDetector';
import ScreenTypeContext from '@js/context/ScreenTypeContext';
import FixedBuyButton from '@jsv2/components/FixedBuyButton/FixedBuyButton';
import ActivityDaemon from '@js/services/ActivityDaemon';
import hashChangeListener from '@js/services/hashChangeListener';
import StartupNotifications from '@js/services/StartupNotifications';
import echoClient from '@js/echoClient';
import { SCREEN_TYPES } from '@js/constants';
import PushNotifications from '@js/push-notifications/PushNotifications';
import Header from '@jsv2/components/Header/Header';
import { isImpersonated } from '@jsv2/utils/UserUtils/userStatuses';
import LiveChat from '@jsv2/components/LiveChat';
import RestoreAbandonedCartModal from '@jsv3/pages/MainApp/UI/RestoreAbandonedCartModal';
import ConfirmedChannelModal from '@jsv3/pages/MainApp/UI/ConfirmedChannelModal';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleOneTap } from '@js/GoogleOneTap';
import configureStore from './configureStore';
import Footer from './components/Footer/index';
import AdProcessor from './AdProcessor';
import EventProcessor from './EventProcessor';
import PasswordRequest from './PasswordRequest';
import ErrorBoundaryDecorator from './components/decorators/ErrorBoundaryDecorator';
import sessionStorageUtils from './sessionStorageUtils';

// export store to be able to set some page-specific state in parent component
export const store = configureStore();

const { userRoles, user, hasConfirmedChannel, __SERVER_DATA__ } = window;

const { hasAbandonedCart, abandonedCart, googleClientId } = __SERVER_DATA__;

sessionStorageUtils.init();

new ActivityDaemon().run();
new StartupNotifications().run();
hashChangeListener.init();

/**
 * This component contains regular page layout.
 * Please make sure you IMPORT IT BEFORE ANY OTHER COMPONENT.
 * It's important coz logger and polyfills will not be enabled otherwise
 */
@ErrorBoundaryDecorator(true)
class App extends Component {
  static propTypes = {
    className: PropTypes.string,
    isErrorPage: PropTypes.bool,
    hideHeader: PropTypes.bool,
  };

  static defaultProps = {
    className: 'app',
    isErrorPage: false,
    hideHeader: false,
  };

  state = {
    screenType: getCurrentScreenType(),
    isChatAvailable: user !== null,
  };

  componentDidMount() {
    this.setScreenHeight();
    this.debouncedResizeHandler = _.debounce(this.onResize, 250);
    window.addEventListener('resize', this.debouncedResizeHandler);

    if (user) {
      echoClient
        .channel(`private-App.User.${user.id}`)
        .listen('StylistOnlineStatusUpdated', (e) => {
          window.userStylist.is_online = e.isOnline;
        });

      PushNotifications.init();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResizeHandler);
  }

  onResize = () => {
    const screenType = getCurrentScreenType();

    if (screenType !== this.state.screenType) {
      this.setState({ screenType });
    }

    this.setScreenHeight();
  };

  /**
   * Add browser screen size by height to css variable (used in css).
   */
  setScreenHeight = () => {
    const screenHeight = window.innerHeight + 'px';
    const rootPageElement = document.getElementsByTagName('html')[0] || null;

    if (rootPageElement) {
      rootPageElement.style.setProperty('--screen-height', screenHeight);
    }
  };

  renderActionButton = () => {
    if (this.state.screenType === SCREEN_TYPES.MOBILE) {
      return <FixedBuyButton />;
    }

    return null;
  };

  renderWarningRibbon = () => {
    const userName = user.full_name || 'id ' + user.id;

    return <div className="warning-ribbon">Currently impersonating: {userName}</div>;
  };

  render() {
    const { screenType, isChatAvailable } = this.state;
    const { className, isErrorPage } = this.props;

    return (
      <Provider store={store}>
        <ScreenTypeContext.Provider value={screenType}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <GoogleOneTap />

            <div id="app-root" className={classNames(className)}>
              {!this.props.hideHeader && <Header />}

              <div className={`content ${isImpersonated(userRoles) ? 'is-impersonated' : ''}`}>
                {this.props.children}

                {this.renderActionButton()}
              </div>

              <Footer links={window.__SERVER_DATA__.shared_data.navigation} />

              {isChatAvailable && <LiveChat />}

              {isImpersonated(userRoles) && this.renderWarningRibbon()}

              {!isErrorPage && <AdProcessor />}

              <EventProcessor />

              {user && user.no_password && <PasswordRequest user={user} />}

              {hasAbandonedCart && <RestoreAbandonedCartModal cart={abandonedCart} />}

              {user && !hasConfirmedChannel && <ConfirmedChannelModal cart={abandonedCart} />}
            </div>
          </GoogleOAuthProvider>
        </ScreenTypeContext.Provider>
      </Provider>
    );
  }
}

export default App;
